<template>
  <div>
    <mainComponent
      :currentCrop.sync="currentCrop"
      :data="mainSection"
      :businesses="businesses"
      :formData.sync="formData"
      :initDatesSynced.sync="initDates"
      @videomodal="modals.video.showed = true"
      @order="onOrder"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import partnerCodeMixin from '@/mixins/partner-code';
import { postOrderProduct } from '@/api/order';
import { buy, sections, custom } from '@/enums/stringIndices';
import wpSection from '@/enums/wpSection';

export default {
  mixins: [common, partnerCodeMixin],
  components: {
    mainComponent: () => import(/* webpackChunkName: "mainComponent" */ '@/components/sections/main/mainIndex.vue'),
  },
  data() {
    return {
      modals: {
        video: {
          showed: false,
          title: '',
        },
      },
      str: {
        custom,
        sections,
        buy,
      },
      formData: {},
      searchBotIndex: true, /** allow search index for robots */
      currentCrop: '',
      initDates: [],
    };
  },
  computed: {
    ...mapState(['menu', 'langs', 'lang', 'logo', 'wpBaseUrl', 'strings']),
    ...mapState({
      crops: (state) => state.settings.crops,
    }),
    mainSection() {
      // set data for mainSection
      const cropName = (this.crops || []).filter((c) => c.id === this.formData.crop)[0]?.title;
      return {
        title: this.str.custom.products_title,
        form: {
          product_title: this.str.custom.products_select_title,
        },
        button: this.format(this.str.custom.insure_button, cropName),
        buttonDataAttr: {
          name: 'data-weather_direction',
          value: this.formData.crop,
        },
      };
    },
    businesses() {
      // filter branches by product
      return (this.str.custom.branches)
        .filter((i) => i.product.includes(this.formData?.crop));
    },
  },
  mounted() {
    this.$root.$on('resize', this.resize);
    this.$root.$on('scroll', this.scroll);
  },
  destroyed() {
    // unwatch
    this.$root.$off('resize', this.resize);
    this.$root.$off('scroll', this.scroll);
  },
  created() {
    this.init();
  },
  watch: {
    crops: {
      immediate: true,
      handler(crops) {
        const cropIds = crops.map((c) => c.id);
        if (cropIds.length > 0 && !cropIds.includes(this.currentCrop)) {
          [this.currentCrop] = cropIds;
        }
      },
    },
    businesses: {
      handler(businesses) {
        if (businesses.length === 0) return;
        const { branch_id } = this.$route.query;
        if (branch_id) {
          const business = businesses.filter((b) => `${b.id}` === `${branch_id}`)[0];
          if (business) this.$set(this.formData, 'business', business);
        }
      },
    },
  },
  methods: {
    init() {
      const {
        weather_direction,
        dates,
        start_date,
        end_date,
      } = this.$route.query;
      if (weather_direction) this.currentCrop = weather_direction;

      const startValid = this.$moment(start_date, 'YYYY-MM-DD', true).isValid();
      const endValid = this.$moment(end_date, 'YYYY-MM-DD', true).isValid();
      const intervalValid = this.$moment(start_date, 'YYYY-MM-DD').isBefore(this.$moment(end_date, 'YYYY-MM-DD'))
        || start_date === end_date;

      if (dates) {
        // set individual dates
        const datesArray = dates.split(',')
          .filter((d) => this.$moment(d, 'YYYY-MM-DD', true).isValid())
          .map((d) => this.$moment(d, 'YYYY-MM-DD', true));
        this.initDates = Array.from(datesArray);
      } else if (startValid && endValid && intervalValid) {
        // set interval
        const datesArray = this.$moment.range(this.$moment(start_date, 'YYYY-MM-DD'), this.$moment(end_date, 'YYYY-MM-DD')).by('days');
        this.initDates = Array.from(datesArray);
      }

      Promise.all([
        this.getWordPressStrings(
          [
            wpSection.CUSTOM,
            wpSection.SECTIONS,
            wpSection.BUY,
          ],
        ),
        this.$store.dispatch('settings/getProductSettings', this.$route.name),
      ])
        .then(() => {
          this.setCrops();
        });
    },
    resize() {
      // call on event window.resize
    },
    scroll() {
      // call on event window.scroll
    },
    order() {
      /** call api order */
      const weather = {
        type: this.formData.crop.includes('snow') ? 'snow' : 'temperature',
        direction: this.formData.crop,
      };
      const data = {
        business: {
          type: 'custom',
          branch_id: this.formData.business.id,
        },
        weather: {
          type: weather.type,
          direction: weather.direction,
        },
        dates: this.formData.dates?.map((date) => this.$moment(date).format('YYYY-MM-DD')),
        partner_code: this.partnerCode,
      };

      /**
       * add parameter  keep_order
       */
      if (`${this.$route.query.keep_order}` === '1') {
        data.keep_order = true;
      }

      // send
      return postOrderProduct(data);
    },
    setCrops() {
      const filteredProducts = this.str.custom.products?.filter((c) => c.active);
      const crops = filteredProducts?.map((el) => ({
        id: el.id,
        title: el.title,
        photo_url: el.photo.url,
        photo_srcset: el.photo.sizes,
      })) || [];
      this.$store.commit('settings/SET_CROPS', crops);
    },
    async onOrder() {
      const modal = {
        id: 'wet-buy-product-not-supported',
        content: `<div class="text-center">${this.str.buy.error_product_not_active}</div>`,
        actions: [
          {
            name: 'wet-buy-product-not-supported',
            title: this.strings.commonApp?.ok,
            event: 'close',
          },
        ],
        close: this.onCloseModal,
      };
      this.$store.dispatch('addModal', modal);
      /**
      await this.$login();
      await this.order()
        .then(() => {
          let name = 'location';

          if (this.$route.query?.redirect
            && this.$route.query.weather_direction === this.formData.crop) {
            name = this.$route.query.redirect;
          }

          // redirect if success
          this.$router.push({ name, params: { ...this.$route.params } });
        });
        */
    },
  },
};
</script>
